import { Component, Input } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select } from '@ngxs/store';
import { AuthState } from '@shared/states/auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-error-message',
  templateUrl: './auth-error-message.component.html',
  styleUrls: ['./auth-error-message.component.scss'],
})
export class AuthErrorMessage {
  @Input() error: AuthError | null = null;

  @Select(AuthState.authError)
  authError$!: Observable<AuthError>;
}
