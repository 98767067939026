export enum AuthView {
  RESET_PASSWORD = 'RESET_PASSWORD',
  INVALID_VERIFICATION = 'INVALID_VERIFICATION',
  REPORT_AUTHENTICATION = 'REPORT_AUTHENTICATION',

  SIGNIN_SELECT_METHOD = 'SIGNIN_SELECT_METHOD',
  SIGNIN_SELECT_INVITE = 'SIGNIN_SELECT_INVITE',

  SIGNUP_SELECT_METHOD = 'SIGNUP_SELECT_METHOD',
  SIGNUP_GOOGLE_PROGRESS = 'SIGNUP_GOOGLE_PROGRESS',
  SIGNUP_MICROSOFT_PROGRESS = 'SIGNUP_MICROSOFT_PROGRESS',
  SIGNUP_CONFIRM_EMAIL = 'SIGNUP_CONFIRM_EMAIL',
  SIGNUP_CONFIRM_PROVIDER = 'SIGNUP_CONFIRM_PROVIDER',
  SIGNUP_INVITES_FOUND = 'SIGNUP_INVITES_FOUND',
  SIGNUP_ACCOUNT_EXIST = 'SIGNUP_ACCOUNT_EXIST',
  SIGNUP_CHECK_EMAIL = 'SIGNUP_CHECK_EMAIL',
  SIGNUP_GOOGLE_DONE = 'SIGNUP_GOOGLE_DONE',
  SIGNUP_MICROSOFT_DONE = 'SIGNUP_MICROSOFT_DONE',
  SIGNUP_VERIFIED = 'SIGNUP_VERIFIED',
  SIGNUP_COMPLETE = 'SIGNUP_COMPLETE',
  SIGNUP_CANCEL = 'SIGNUP_CANCEL',

  INVITE_ACCEPT = 'INVITE_ACCEPT',
  INVITE_ERROR = 'INVITE_ERROR',

  SSO_DOMAIN_LOGIN = 'SSO_DOMAIN_LOGIN',
  SSO_EMAIL_LOGIN = 'SSO_EMAIL_LOGIN',
}

export enum AuthError {
  ACCOUNT_NOT_EXIST = 'ACCOUNT_NOT_EXIST',
  GOOGLE_PROVIDER_ERROR = 'GOOGLE_PROVIDER_ERROR',
  MICROSOFT_PROVIDER_ERROR = 'MICROSOFT_PROVIDER_ERROR',
  NO_GOOGLE_PROVIDER = 'NO_GOOGLE_PROVIDER',
  NO_MICROSOFT_PROVIDER = 'NO_MICROSOFT_PROVIDER',
  PASSWORD_PROVIDER_ERROR = 'PASSWORD_PROVIDER_ERROR',
  NO_PASSWORD_PROVIDER = 'NO_PASSWORD_PROVIDER',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  INVALID_EMAIL = 'INVALID_EMAIL',
  NO_PASSWORD_MATCH = 'NO_PASSWORD_MATCH',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',
  PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED',
  PASSWORD_TOO_WEAK = 'PASSWORD_TOO_WEAK',
  PASSWORD_MISMATCH = 'PASSWORD_MISMATCH',
  PASSWORD_NOT_CORRECT = 'PASSWORD_NOT_CORRECT',
  INVITE_WRONG_ACCOUNT = 'INVITE_WRONG_ACCOUNT',
  INVITE_KEY_EXPIRED = 'INVITE_KEY_EXPIRED',
  INVITE_KEY_INVALID = 'INVITE_KEY_INVALID',
  NO_REPORT_EMAIL_MATCH = 'NO_REPORT_EMAIL_MATCH',
  NO_GDPR_CONSENT = 'NO_GDPR_CONSENT',
  SSO_LOGIN_ERROR = 'SSO_LOGIN_ERROR',
}
