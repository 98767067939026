import { Component, OnInit } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select, Store } from '@ngxs/store';
import { AuthenticationError } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type PasswordData = {
  value: string;
  score: number;
  warning: string;

  isMatched: boolean;
  isStrong: boolean;

  hasLower: boolean;
  hasUpper: boolean;
  hasNumber: boolean;
  hasSpecial: boolean;
  hasLength: boolean;
};

@Component({
  selector: 'auth-passwords',
  templateUrl: './auth-passwords.component.html',
  styleUrls: ['./auth-passwords.component.scss'],
  exportAs: 'AuthPasswords',
})
export class AuthPasswords implements OnInit {
  readonly AuthError = AuthError;
  password1: string = '';
  password2: string = '';

  strength$ = new BehaviorSubject<Partial<PasswordData>>({ score: null });

  @Select(AuthState.authError)
  authError$!: Observable<AuthError>;

  constructor(private store: Store) {}

  get data(): PasswordData {
    const password = this.password1;

    const hasLower = /[a-z]/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    const hasLength = password.length >= 8;

    const pwdChecks = { hasLower, hasUpper, hasNumber, hasSpecial, hasLength };
    const pwdCheckCount = Object.values(pwdChecks).filter(Boolean).length;

    const { score, feedback } = zxcvbn(password);
    const { warning } = feedback;

    const scoreAdjustment = score + (pwdCheckCount === 5 ? 1 : 0);
    const isMatched = password === this.password2;
    const isStrong = scoreAdjustment === 5;
    const value = isMatched ? password : '';

    return { value, score: scoreAdjustment, warning, isMatched, isStrong, ...pwdChecks };
  }

  ngOnInit() {
    this.setupZxcvbn();
  }

  private async setupZxcvbn() {
    const zxcvbnCommonPackage = await import(
      /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
    );
    const locale = this.store.selectSnapshot(PrefsState.language);

    if (locale === 'fi') {
      const zxcvbnFiPackage = await import(/* webpackChunkName: "zxcvbnFiPackage" */ '@zxcvbn-ts/language-fi');
      const options = {
        dictionary: {
          ...zxcvbnCommonPackage.dictionary,
          ...zxcvbnFiPackage.dictionary,
        },
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        translations: zxcvbnFiPackage.translations,
      };

      zxcvbnOptions.setOptions(options);
    } else {
      const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en');
      const options = {
        dictionary: {
          ...zxcvbnCommonPackage.dictionary,
          ...zxcvbnEnPackage.dictionary,
        },
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        translations: zxcvbnEnPackage.translations,
      };

      zxcvbnOptions.setOptions(options);
    }
  }

  checkPassword() {
    this.store.dispatch(new AuthenticationError(null));

    // console.warn(this.data);
    this.strength$.next(this.data);
  }
}
