<div *ngIf="strength$ | async as asyncData" class="z-fx-col z-fx-gap-8 z-fx-auto">
  <div [class.invisible]="!pwd1.focused">
    <span
      class="zef-chip-small"
      [ngClass]="asyncData.hasLower ? 'zef-bgcolor-accent' : 'zef-bgcolor-space-darker'"
      i18n
    >
      small letters
    </span>
    <span
      class="zef-chip-small"
      [ngClass]="asyncData.hasUpper ? 'zef-bgcolor-accent' : 'zef-bgcolor-space-darker'"
      i18n
    >
      capital letters
    </span>
    <span
      class="zef-chip-small"
      [ngClass]="asyncData.hasNumber ? 'zef-bgcolor-accent' : 'zef-bgcolor-space-darker'"
      i18n
    >
      numbers
    </span>
    <span
      class="zef-chip-small"
      [ngClass]="asyncData.hasSpecial ? 'zef-bgcolor-accent' : 'zef-bgcolor-space-darker'"
      i18n
    >
      special characters
    </span>
    <span
      class="zef-chip-small"
      [ngClass]="asyncData.hasLength ? 'zef-bgcolor-accent' : 'zef-bgcolor-space-darker'"
      i18n
    >
      8 or more characters
    </span>
  </div>

  <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.PASSWORD_TOO_WEAK">
    <mat-icon matPrefix [class.zef-color-ink-lightest]="!password1">lock</mat-icon>
    <input
      matInput
      #pwd1="matInput"
      name="password1"
      type="password"
      placeholder="Set a password..."
      i18n-placeholder="placeholder|sign-in password1 input"
      [(ngModel)]="password1"
      (input)="checkPassword()"
    />

    <svg
      class="signal-bars"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      [attr.data-strength]="asyncData?.score"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 24C5.44772 24 5 24.4477 5 25V27C5 27.5523 5.44772 28 6 28C6.55228 28 7 27.5523 7 27V25C7 24.4477 6.55228 24 6 24Z"
      />
      <path
        d="M10 20C10 19.4477 10.4477 19 11 19C11.5523 19 12 19.4477 12 20V27C12 27.5523 11.5523 28 11 28C10.4477 28 10 27.5523 10 27V20Z"
      />
      <path
        d="M16 14C15.4477 14 15 14.4477 15 15V27C15 27.5523 15.4477 28 16 28C16.5523 28 17 27.5523 17 27V15C17 14.4477 16.5523 14 16 14Z"
      />
      <path
        d="M20 10C20 9.44771 20.4477 9 21 9C21.5523 9 22 9.44772 22 10V27C22 27.5523 21.5523 28 21 28C20.4477 28 20 27.5523 20 27V10Z"
      />
      <path
        d="M25 5C25 4.44772 25.4477 4 26 4C26.5523 4 27 4.44772 27 5V27C27 27.5523 26.5523 28 26 28C25.4477 28 25 27.5523 25 27V5Z"
      />
    </svg>
  </mat-form-field>

  <auth-error-message [error]="AuthError.PASSWORD_TOO_WEAK" i18n>Password too weak</auth-error-message>

  <span *ngIf="asyncData.warning" class="zef-text-p2 zef-text-muted">{{ asyncData.warning }}</span>

  <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.PASSWORD_MISMATCH">
    <mat-icon matPrefix [class.zef-color-ink-lightest]="!password2">lock</mat-icon>
    <input
      matInput
      name="password2"
      type="password"
      placeholder="Repeat password..."
      i18n-placeholder="placeholder|sign-in password2 input"
      [(ngModel)]="password2"
      (input)="checkPassword()"
    />

    <mat-icon
      matSuffix
      *ngIf="password2"
      [ngClass]="password1 === password2 ? 'zef-color-success' : 'zef-color-accent'"
      [matTooltip]="password1 === password2 ? null : 'Passwords don\'t match'"
      matTooltipPosition="above"
      i18n-matTooltip
    >
      {{ password1 === password2 ? 'check' : 'editor_alert' }}
    </mat-icon>
  </mat-form-field>

  <auth-error-message [error]="AuthError.PASSWORD_MISMATCH" i18n>Passwords don’t match</auth-error-message>
  <auth-error-message [error]="AuthError.PASSWORD_RESET_FAILED" i18n>Failed to reset password</auth-error-message>
</div>
