<span class="zef-text-p2 zef-hyper-link zef-color-muted" [inlineDialogTriggerFor]="resetPasswordDialog" i18n>
  Forgot password?
</span>

<zef-inline-dialog #resetPasswordDialog="inlineDialog" color="primary">
  <ng-container *dialogTitle i18n>Reset password</ng-container>

  <div *dialogContent class="z-fx-col z-fx-gap-24">
    <ng-container *ngIf="isResetEmailSent; else emailForm">
      <span class="zef-text-p1" i18n>
        If we find an account registered with
        <strong>{{ email }}</strong>
        , you’ll soon receive a password-reset link.
      </span>

      <button
        mat-raised-button
        color="secondary"
        class="zef-stretch-button"
        (click)="resetPasswordDialog.closeDialog()"
        i18n
      >
        OK
      </button>
    </ng-container>

    <ng-template #emailForm>
      <span class="zef-text-p1" i18n>Enter the email address, and we'll send you a link to reset your password.</span>

      <div class="z-fx-col z-fx-gap-8">
        <mat-form-field
          class="auth-input-field auth-input-shadow zef-input-combo"
          [authErrorShake]="AuthError.PASSWORD_RESET_ERROR"
        >
          <mat-icon matPrefix [class.zef-color-ink-lightest]="!email">email_address</mat-icon>
          <input
            matInput
            name="email"
            type="email"
            placeholder="Email..."
            i18n-placeholder="placeholder|sign-in email input"
            [(ngModel)]="email"
            (input)="clearError()"
          />
        </mat-form-field>

        <auth-error-message [error]="AuthError.PASSWORD_RESET_ERROR" i18n>Invalid email address</auth-error-message>

        <button
          mat-raised-button
          color="primary"
          class="zef-stretch-button"
          [disabled]="sending$ | async"
          [class.loading]="sending$ | async"
          (click)="resetPassword(email)"
          i18n
        >
          Send reset link
        </button>
      </div>
    </ng-template>
  </div>
</zef-inline-dialog>
