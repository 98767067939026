var l33tTable = {
  a: ['4', '@'],
  b: ['8'],
  c: ['(', '{', '[', '<'],
  d: ['6', '|)'],
  e: ['3'],
  f: ['#'],
  g: ['6', '9', '&'],
  h: ['#', '|-|'],
  i: ['1', '!', '|'],
  k: ['<', '|<'],
  l: ['!', '1', '|', '7'],
  m: ['^^', 'nn', '2n', '/\\\\/\\\\'],
  n: ['//'],
  o: ['0', '()'],
  q: ['9'],
  u: ['|_|'],
  s: ['$', '5'],
  t: ['+', '7'],
  v: ['<', '>', '/'],
  w: ['^/', 'uu', 'vv', '2u', '2v', '\\\\/\\\\/'],
  x: ['%', '><'],
  z: ['2']
};

export { l33tTable as default };

