import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Store } from '@ngxs/store';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { AuthState } from '@shared/states/auth.state';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[authErrorShake]',
  providers: [LifecycleHooks],
})
export class AuthErrorShake implements OnInit {
  constructor(
    private eRef: ElementRef,
    private lh: LifecycleHooks,
    private store: Store,
    private renderer: Renderer2,
  ) {}

  @Input('authErrorShake') error: AuthError | null = null;

  ngOnInit() {
    this.store
      .select(AuthState.authError)
      .pipe(takeUntil(this.lh.destroy))
      .subscribe((authError) => {
        if (authError === this.error) {
          this.renderer.addClass(this.eRef.nativeElement, 'auth-input-error');
          this.renderer.addClass(this.eRef.nativeElement, 'auth-error-shake');
        } else {
          this.renderer.removeClass(this.eRef.nativeElement, 'auth-input-error');
          this.renderer.removeClass(this.eRef.nativeElement, 'auth-error-shake');
        }
      });
  }
}
